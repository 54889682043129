
export default {
  name: 'SocialIcon',
  props: {
    url: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    getIcon() {
      return require(`~/assets/svg/${this.icon}`)
    },
  },
}
